import { Button, Flex, Spacer } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrementLocation, incrementLocation, disableNext, allowNext } from "../store/form";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const FormPage = (props) => {
  const location = useSelector((state) => state.form.formLocation);
  const formFlow = useSelector((state) => state.form.formFlow);
  const nextIsDisabled = useSelector((state) => state.form.nextIsDisabled);
  const dispatch = useDispatch();

  const nextPage = (id) => {
    if (id === 5) {
      dispatch(disableNext());
    }
    dispatch(incrementLocation());
  };

  const prevPage = () => {
    dispatch(decrementLocation());
    dispatch(allowNext());
  };

  return (
    props.id === formFlow[location] && (
      <>
        {props.children}
        <Flex pb="1rem">
          {(location / (formFlow.length - 1)) * 100 !== 0 && (
            <Button onClick={prevPage}>
              <ChevronLeftIcon /> &nbsp; Back
            </Button>
          )}
          <Spacer />
          {(location / (formFlow.length - 1)) * 100 !== 100 && (
            <Button onClick={() => nextPage(props.id)} colorScheme="messenger" disabled={nextIsDisabled}>
              Next &nbsp; <ChevronRightIcon />
            </Button>
          )}
        </Flex>
      </>
    )
  );
};

export default FormPage;
