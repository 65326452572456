import React from "react";

import { Box, Button, Container, Flex, Heading, Image, List, ListIcon, ListItem, Modal, Stack, Text, useDisclosure, VStack, Wrap } from "@chakra-ui/react";
import { ReactComponent as RestaurantIcon } from "../assets/noun-restaurant-1949935.svg";
import { ReactComponent as FoodBagIcon } from "../assets/noun-food-bag-2835432.svg";
import { ReactComponent as FoodTruckIcon } from "../assets/noun-food-truck-4517551.svg";
import { ReactComponent as CateringIcon } from "../assets/noun-catering-4619935.svg";
import { ReactComponent as StoreIcon } from "../assets/noun-small-store-3511802.svg";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import FormModal from "../components/FormModal";
import IconButtonSquare from "../components/IconButtonSquare";
import { useDispatch } from "react-redux";
import { changeFlow, restartLocation } from "../store/form";

function HomePage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();

  const startForm = (data) => {
    dispatch(restartLocation());
    dispatch(changeFlow({ flow: data }));
  };

  return (
    <>
      <Flex w={"full"} h={"100vh"} minH={"55rem"} backgroundImage={"url(./mainhero.jpg)"} backgroundSize={"cover"} backgroundPosition={"center center"}>
        <VStack w={"full"} justify={"center"} bgGradient={"linear(to-b, blackAlpha.600, transparent)"}>
          <Stack maxW={"5xl"} align={"center"} spacing={2}>
            <Heading lineHeight={1.1} fontWeight={600} color={"white"} align={"center"} fontSize={{ base: "2xl", sm: "3xl", lg: "5xl" }}>
              Point-of-sale systems to fit your business.
              <br />
              <Text as={"strong"}>From a partner who listens.</Text>
            </Heading>
            <Text color={"white"} fontWeight={600} fontSize={{ base: "1xl", sm: "2xl", lg: "4xl" }} pt={"1rem"}>
              How would you describe your restaurant?
            </Text>
            <Wrap width={["22rem", "39rem"]}>
              <IconButtonSquare text="Full Service Restaurant" onOpen={onOpen} formFlow={{ pages: [3, 4, 5, 6, 7], name: "Full Service Restaurant" }}>
                <RestaurantIcon fill="#1769ff" />
              </IconButtonSquare>
              <IconButtonSquare text="Quick Service Restaurant" onOpen={onOpen} formFlow={{ pages: [3, 4, 5, 6, 7], name: "Quick Service Restaurant" }}>
                <FoodBagIcon fill="#1769ff" />
              </IconButtonSquare>
              <IconButtonSquare text="Food Truck" onOpen={onOpen} formFlow={{ pages: [3, 4, 5, 6, 7], name: "Food Truck" }}>
                <FoodTruckIcon fill="#1769ff" />
              </IconButtonSquare>
              <IconButtonSquare text="Catering" onOpen={onOpen} formFlow={{ pages: [3, 4, 5, 6, 7], name: "Catering" }}>
                <CateringIcon fill="#1769ff" />
              </IconButtonSquare>
              <IconButtonSquare text="Non-Food" onOpen={onOpen} formFlow={{ pages: [1, 2, 3, 4, 5, 6, 7], name: "Non-Food" }}>
                <StoreIcon fill="#1769ff" />
              </IconButtonSquare>
            </Wrap>
          </Stack>
        </VStack>
      </Flex>
      <Container maxW="container.xl" p={["1rem", "3rem", "5rem"]}>
        <Stack align={"center"} spacing={{ base: 8, md: 10 }} direction={{ base: "column", md: "row" }}>
          <Stack flex={1} spacing={{ base: 5, md: 10 }}>
            <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: "2xl", sm: "3xl", lg: "5xl" }}>
              <Text as={"span"}>More than a restaurant POS system.</Text>
              <br />
              <Text as={"span"} color={"blue.100"}>
                An end-to-end restaurant solution.
              </Text>
            </Heading>
            <Text color={"gray.500"}>
              Make your hard work more profitable with tools that work together. Restaurant POS software, online ordering, digital waitlist & reservations, labor management, and
              reporting.
            </Text>
            <Box>
              <Button
                onClick={() => {
                  startForm([3, 4, 5, 6, 7]);
                  onOpen();
                }}
                colorScheme="messenger"
                size="lg"
                px="3rem"
              >
                Learn More
              </Button>
            </Box>
          </Stack>
          <Flex pos={"relative"} flex={1} justify={"center"} align={"center"} w={"full"}>
            <Box pos="absolute" top="-10" left="-10" w={150} boxShadow="dark-lg" borderRadius={"50%"}>
              <Image src={"./partnerBadge.png"} />
            </Box>
            <Box rounded={"2xl"} boxShadow={"2xl"} width={"full"} overflow={"hidden"}>
              <Image alt={"More than a restaurant POS system."} fit={"cover"} align={"center"} w={"100%"} h={"100%"} src={"./pos.webp"} />
            </Box>
          </Flex>
        </Stack>
      </Container>
      <Container maxW="container.xl" centerContent p={["1rem", "3rem", "5rem"]}>
        <Box>
          <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: "2xl", sm: "3xl", lg: "5xl" }}>
            <Text>
              The{" "}
              <Text as={"span"} color={"blue.100"}>
                #1
              </Text>{" "}
              and{" "}
              <Text as={"span"} color={"blue.100"}>
                easiest to use
              </Text>{" "}
              restaurant POS system according to{" "}
              <Text as={"span"} color={"blue.100"}>
                user reviews.
              </Text>
            </Text>
          </Heading>
        </Box>
        <Box m={["1rem", "3rem", "5rem"]} align="center">
          <Image src={"./capterra-restaurant-pos.png"} mb={"5rem"} />
          <Button
            px="4rem"
            onClick={() => {
              startForm([3, 4, 5, 6, 7]);
              onOpen();
            }}
            colorScheme="messenger"
            size="lg"
          >
            Request Info
          </Button>
        </Box>
        <Box m={"1rem"}>
          <Image src={"./badges.png"} mb={"3rem"} />
        </Box>
        <Box m={["1rem", "3rem", "5rem"]} align="center">
          <Heading mb={["1rem", "3rem", "5rem"]} color={"black"} align="center" fontWeight={600} fontSize={{ base: "2xl", sm: "3xl", lg: "5xl" }}>
            Working hard for restaurants like yours
            <Text as={"span"} color={"blue.100"}>
              .
            </Text>
          </Heading>
          <Image src={"./testimonials1.png"} />
          <Button
            mt="3rem"
            px="3rem"
            onClick={() => {
              startForm([3, 4, 5, 6, 7]);
              onOpen();
            }}
            colorScheme="messenger"
            size="lg"
          >
            Let us work for you
          </Button>
        </Box>
        <Box position={"relative"} rounded={"2xl"} boxShadow={"2xl"} overflow={"hidden"}>
          <Text
            color={"white"}
            position={"absolute"}
            left={"50%"}
            top={"50%"}
            transform={"translateX(-50%) translateY(-50%)"}
            fontWeight={600}
            fontSize={{ base: "1xl", sm: "2xl", lg: "3xl" }}
          >
            We are there for you & your customers
            <Text as={"span"} color={"blue.100"}>
              .
            </Text>
          </Text>
          <Text color={"white"} position={"absolute"} left={"50%"} bottom={"1rem"} transform={"translateX(-50%)"} fontWeight={600} fontSize={{ base: "1xl", sm: "2xl", lg: "3xl" }}>
            24{" "}
            <Text as={"span"} color={"blue.100"}>
              /
            </Text>{" "}
            7{" "}
            <Text as={"span"} color={"blue.100"}>
              /
            </Text>{" "}
            365
          </Text>
          <Image alt={"SpotOn White Glove Support"} fit={"cover"} align={"center"} src={"./rest-white-glove.webp"} />
        </Box>
        <Box mt={["1rem", "3rem", "5rem"]}>
          <Heading fontWeight={600} fontSize={{ base: "2xl", sm: "3xl", lg: "5xl" }} color={"blue.100"}>
            White-glove service and support.
          </Heading>
          <Text mb={"4"}>
            You have a lot on your plate, but with SpotOn, you’re no longer on your own. Work with our team or use our browser-based web portal to set up front-of-house policies,
            monitor operations and staffing assignments, and access customer analytics—all with a local, dedicated representative and 24/7 personalized support. We are here for
            you, no matter what.
          </Text>
          <List>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color="blue.100" />
              Premium in-person hardware installation
            </ListItem>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color="blue.100" />
              Face-to-face staff training from SpotOn specialists
            </ListItem>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color="blue.100" />
              Ongoing virtual training for staff and management
            </ListItem>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color="blue.100" />
              24/7 support from SpotOn employees included
            </ListItem>
          </List>
          <Button
            mt="3rem"
            px="4rem"
            onClick={() => {
              startForm([3, 4, 5, 6, 7]);
              onOpen();
            }}
            colorScheme="messenger"
            size="lg"
          >
            Learn More
          </Button>
        </Box>
      </Container>
      <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
        <Container maxW="md">
          <FormModal />
        </Container>
      </Modal>
    </>
  );
}

export default HomePage;
