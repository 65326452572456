import { AspectRatio, Box, WrapItem } from "@chakra-ui/react";
import React from "react";
import { changeFlow, restartLocation, firstChoice } from "../store/form";
import { useDispatch } from "react-redux";

function IconButtonSquare(props) {
  const dispatch = useDispatch();

  const startForm = (data) => {
    dispatch(restartLocation());
    dispatch(changeFlow({ flow: data.pages }));
    dispatch(firstChoice({ choice: data.name }));
  };
  return (
    <WrapItem role="group">
      <Box
        as="button"
        px={[3, 6]}
        color={"blue.100"}
        borderRadius="sm"
        h={["10rem", "12rem"]}
        w={["9rem", "12rem"]}
        bg={"white"}
        border={"1px"}
        borderColor="white"
        _groupHover={{ bg: "gray.100", border: "none" }}
        lineHeight="1.2"
        onClick={() => {
          startForm(props.formFlow);
          props.onOpen();
        }}
      >
        <AspectRatio maxW="400px" ratio={4 / 3}>
          {props.children}
        </AspectRatio>
        {props.text}
        <Box as="div" borderRadius="xl" border={"2px"} borderColor={"black"} bg={"white"} h={["1.5rem"]} w={["1.5rem"]} mt="2" mx="auto" _hover={{ bg: "blue.100" }}>
          &nbsp;
        </Box>
      </Box>
    </WrapItem>
  );
}

export default IconButtonSquare;
