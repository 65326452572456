import { Box, Center, Flex, Image, Spacer } from "@chakra-ui/react";
import React from "react";

function AppHeader() {
  return (
    <Flex
      as="header"
      color="white"
      position="fixed"
      backgroundColor="rgba(0, 
      0, 0, 0.7)"
      backdropFilter="saturate(180%) blur(5px)"
      w="100%"
      padding="4"
      zIndex={"1000"}
    >
      <Center h="70px">
        <Box w="140px" me={2}>
          <Image src="./LogoWhite.png" />
        </Box>
        {/* | Authorized Partner */}
      </Center>
      <Spacer />
      <Center h="70px" fontSize={23} justify="end">
        Preferred Technology Partner of:
        <Box h="90px" ms={2}>
          <Image src="./TRAwhitesmall.png" h="85px" />
        </Box>
      </Center>
    </Flex>
  );
}

export default AppHeader;
