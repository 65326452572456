import { React } from "react";

import { Box, Container, Stack, Text, useColorModeValue } from "@chakra-ui/react";

function AppFooter() {
  return (
    <Box bg={useColorModeValue("gray.50", "gray.900")} color={useColorModeValue("gray.700", "gray.200")}>
      <Container
        as={Stack}
        maxW={"6xl"}
        py={4}
        direction={{ base: "column", md: "row" }}
        spacing={4}
        justify={{ base: "center", md: "space-between" }}
        align={{ base: "center", md: "center" }}
      >
        <Text>
          <b>SpotOn</b> © 2022 All rights reserved • Built By Circle B Graphics
        </Text>
        <Text>Terms Cookies Privacy CCPA Do not sell my data</Text>
      </Container>
    </Box>
  );
}

export default AppFooter;
