import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  formFlow: [3, 4, 5, 6, 7],
  formLocation: 0,
  formEntry: "",
  firstChoice: "",
  nextIsDisabled: false,
};
const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    firstChoice: (state, action) => {
      state.firstChoice = action.payload.choice;
    },
    changeFlow: (state, action) => {
      state.formFlow = action.payload.flow;
    },
    setEntry: (state, action) => {
      state.formEntry = action.payload.entry;
    },
    restartLocation: (state) => {
      state.formLocation = 0;
    },
    incrementLocation: (state) => {
      state.formLocation += 1;
    },
    decrementLocation: (state) => {
      state.formLocation -= 1;
    },
    allowNext: (state) => {
      state.nextIsDisabled = false;
    },
    disableNext: (state) => {
      state.nextIsDisabled = true;
    },
  },
});

export const { firstChoice, changeFlow, setEntry, restartLocation, incrementLocation, decrementLocation, allowNext, disableNext } = formSlice.actions;

export default formSlice.reducer;
