import * as React from "react";
import AppFooter from "./components/AppFooter";
import AppHeader from "./components/AppHeader";

import HomePage from "./views/HomePage";

function App() {
   return (
      <>
         <AppHeader />
         <HomePage />
         <AppFooter />
      </>
   );
}

export default App;
