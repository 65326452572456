import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import store from "../src/store/index";
import { Provider } from "react-redux";

const theme = extendTheme({
  colors: {
    blue: {
      30: "#e8f0ff",
      50: "#d1e1ff",
      70: "#74a5ff",
      100: "#1769ff",
      150: "#0e3f99",
      200: "#092a66",
      300: "#07204d",
    },
  },
  fonts: {
    heading: `'sofia-pro', sans-serif`,
    body: `'sofia-pro', sans-serif`,
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </ChakraProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
