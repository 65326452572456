import {
  AbsoluteCenter,
  Alert,
  AlertIcon,
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Heading,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Progress,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ReactComponent as ComingSoonIcon } from "../assets/coming-soon.svg";
import { ReactComponent as OpenIcon } from "../assets/open.svg";
import FormPage from "./FormPage";
import { useDispatch, useSelector } from "react-redux";
import RadioBox from "./RadioBox";
import { allowNext, disableNext, incrementLocation } from "../store/form";
import { CheckIcon } from "@chakra-ui/icons";

function FormModal() {
  const dispatch = useDispatch();
  const location = useSelector((state) => state.form.formLocation);
  const formFlow = useSelector((state) => state.form.formFlow);
  const firstChoice = useSelector((state) => state.form.firstChoice);
  const [infoSent, setInfoSent] = useState(false);
  const [progressOffset, setProgressOffset] = useState(70);
  let progress = (location / (formFlow.length - 1)) * progressOffset + (100 - progressOffset);
  useEffect(() => {
    if (formFlow.length > 6) {
      setProgressOffset(90);
    }
  }, [formFlow.length]);

  const [newBiz, setNewBiz] = useState("new");
  const [terminals, setTerminals] = useState("1");
  const [company, setCompany] = useState();
  const [fName, setFName] = useState();
  const [lName, setLName] = useState();
  const [phone, setPhone] = useState();
  const [email, setEmail] = useState();
  const [zip, setZip] = useState();
  const [nonfoodbiz, setNonfoodbiz] = useState();
  const [sysorcomp, setSysorcomp] = useState();
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(true);
  const validateEmail = (email) => {
    return email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  };
  const validatePhone = (phone) => {
    return phone.match(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
  };
  const formData = {
    firstChoice,
    company,
    fname: fName,
    lname: lName,
    email,
    phone,
    zip,
    nonfoodbiz,
    sysorcomp,
    newex: newBiz,
    posterminals: terminals,
  };
  const setTextField = (event) => {
    switch (event.target.name) {
      case "company":
        setCompany(event.target.value);
        break;
      case "fName":
        setFName(event.target.value);
        break;
      case "lName":
        setLName(event.target.value);
        break;
      case "phone":
        setPhone(event.target.value);
        if (validatePhone(event.target.value)) {
          setPhoneError(false);
          dispatch(allowNext());
        } else {
          setPhoneError(true);
          dispatch(disableNext());
        }
        break;
      case "email":
        setEmail(event.target.value);
        if (validateEmail(event.target.value)) {
          setEmailError(false);
          dispatch(allowNext());
        } else {
          setEmailError(true);
          dispatch(disableNext());
        }
        break;
      case "zip":
        setZip(event.target.value);
        break;
      default:
      // Do nothing
    }
  };

  const nonfoodbizHandleChange = (e) => {
    setNonfoodbiz(e);
    dispatch(incrementLocation());
  };

  const sysorcompHandleChange = (e) => {
    setSysorcomp(e);
    dispatch(incrementLocation());
  };

  const newexHandleChange = (e) => {
    setNewBiz(e);
    dispatch(incrementLocation());
  };

  const terminalsHandleChange = (e) => {
    setTerminals(e);
    dispatch(incrementLocation());
  };

  const onKeyUpValue = (e) => {
    if (e.key === "Enter") {
      dispatch(incrementLocation());
      e.target.name === "zip" && dispatch(disableNext());
    }
  };

  const handlePost = (event) => {
    event.preventDefault();

    fetch("https://hook.us1.make.com/vwa8h45vv2fpwoptejk2cf83clwps1zk", {
      // Enter your IP address here
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(formData), // body data type must match "Content-Type" header
    }).then(() => {
      setInfoSent(true);
    });
  };

  return (
    <>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>SpotOn POS Quote</ModalHeader>
        <ModalCloseButton />
        <Progress value={progress} height="32px" colorScheme="messenger" />
        {progress < 100 && (
          <Text ms={progress + "%"} mt="-1.8rem" ps=".5rem" position="relative" zIndex="100">
            {progress}%
          </Text>
        )}
        {progress === 100 && (
          <Text align="right" me="1rem" mt="-1.8rem" ps=".5rem" position="relative" zIndex="100" color="white">
            100%
          </Text>
        )}
        <ModalBody align="center" minH="47rem">
          <AbsoluteCenter>
            {infoSent && (
              <Box>
                <CheckIcon boxSize={100} color="blue.100" mb={5} />
                <Heading>Thank You!</Heading>
                We've recieved your request.
              </Box>
            )}
            {!infoSent && (
              <Stack maxW="xxl" spacing={6}>
                <FormPage id={1}>
                  <Heading>How would you describe your business?</Heading>
                  <RadioGroup onChange={nonfoodbizHandleChange} value={nonfoodbiz}>
                    <Flex>
                      <Stack w="50%">
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Retail">Retail</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Convenience">Comvenience Store</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Grocery/Supermarket">Grocery/Supermarket</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Liquor/Wine">Liquor/Wine</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Florist">Florist</Radio>
                        </RadioBox>
                      </Stack>
                      <Stack w="50%">
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Furniture">Furniture/Home Decor</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Wireless">Wireless/Cellular</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Building/Garden/Industrial">Building/Garden/Industrial</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Car Wash">Car Wash</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Hotel/Inn">Hotel/Inn</Radio>
                        </RadioBox>
                        <RadioBox boxWidth="100%" boxAlign="left">
                          <Radio value="Other">Other Business</Radio>
                        </RadioBox>
                      </Stack>
                    </Flex>
                  </RadioGroup>
                </FormPage>
                <FormPage id={2}>
                  <Heading>Are you interested in a complete POS System or just specific components?</Heading>
                  <RadioGroup onChange={sysorcompHandleChange} value={sysorcomp}>
                    <Stack>
                      <RadioBox boxWidth="100%" boxAlign="left">
                        <Radio value="Complete POS system">Complete POS system</Radio>
                      </RadioBox>
                      <RadioBox boxWidth="100%" boxAlign="left">
                        <Radio value="Hardware only">Hardware only</Radio>
                      </RadioBox>
                      <RadioBox boxWidth="100%" boxAlign="left">
                        <Radio value="Software only">Software only</Radio>
                      </RadioBox>
                      <RadioBox boxWidth="100%" boxAlign="left">
                        <Radio value="Cash register only">Cash register only</Radio>
                      </RadioBox>
                    </Stack>
                  </RadioGroup>
                </FormPage>
                <FormPage id={3}>
                  <Heading>Is your business new or existing?</Heading>
                  <RadioGroup onChange={newexHandleChange} value={newBiz} width="100%">
                    <Flex>
                      <RadioBox boxWidth="50%">
                        <ComingSoonIcon fill="#1769ff" />
                        <Radio value="new" my="0.5rem">
                          New, Just Browsing
                        </Radio>
                      </RadioBox>
                      <RadioBox boxWidth="50%">
                        <OpenIcon fill="#1769ff" />
                        <Radio value="existing" my="0.5rem">
                          Existing
                        </Radio>
                      </RadioBox>
                    </Flex>
                  </RadioGroup>
                </FormPage>
                <FormPage id={4}>
                  <Heading>How many POS terminals do you need?</Heading>
                  <RadioGroup onChange={terminalsHandleChange} value={terminals}>
                    <Stack>
                      <RadioBox boxWidth="100%" boxAlign="left">
                        <Radio value="5+">5+</Radio>
                      </RadioBox>
                      <RadioBox boxWidth="100%" boxAlign="left">
                        <Radio value="3-5">3-5</Radio>
                      </RadioBox>
                      <RadioBox boxWidth="100%" boxAlign="left">
                        <Radio value="2">2</Radio>
                      </RadioBox>
                      <RadioBox boxWidth="100%" boxAlign="left">
                        <Radio value="1">1</Radio>
                      </RadioBox>
                    </Stack>
                  </RadioGroup>
                </FormPage>
                <FormPage id={5}>
                  <Heading>What is your zip code?</Heading>
                  <FormControl w="100%">
                    <Input onChange={setTextField} defaultValue={zip} name="zip" placeholder="54321" onKeyUp={onKeyUpValue.bind(this)} />
                    <FormHelperText>Your zip code helps us provide you with the best quote for your business.</FormHelperText>
                  </FormControl>
                </FormPage>
                <FormPage id={6} requiredText={email}>
                  <Heading>What email address can we use to contact you?</Heading>
                  <FormControl w="100%" isInvalid={emailError}>
                    <Input
                      isRequired={true}
                      type="email"
                      onChange={setTextField}
                      defaultValue={email}
                      name="email"
                      placeholder="john.smith@gmail.com"
                      onKeyUp={onKeyUpValue.bind(this)}
                    />
                    {!emailError ? (
                      <FormHelperText>Great, you're one step closer to recieving a free quote!</FormHelperText>
                    ) : (
                      <FormErrorMessage>A valid email is required.</FormErrorMessage>
                    )}
                  </FormControl>
                </FormPage>
                <FormPage id={7}>
                  <Heading>Last Step—Contact Information:</Heading>
                  <Input onChange={setTextField} defaultValue={company} name="company" placeholder="Company Name" />
                  <Input onChange={setTextField} defaultValue={fName} name="fName" placeholder="First Name" />
                  <Input onChange={setTextField} defaultValue={lName} name="lName" placeholder="Last Name" />
                  <FormControl isInvalid={phoneError}>
                    <Input onChange={setTextField} defaultValue={phone} name="phone" placeholder="(111) 222-3456" />
                    {phoneError && <FormErrorMessage>A valid phone is required.</FormErrorMessage>}
                  </FormControl>
                  <Button onClick={handlePost} type="button" colorScheme="green" disabled={phoneError}>
                    Send Me Info!
                  </Button>

                  <Alert status="info" variant="solid">
                    <AlertIcon />
                    <small>
                      By providing a wireless or residential phone number above, you agree that BeaconPay may make telemarketing and other calls and text messages about your
                      request and other products/services to you at the number you supplied. These calls may be made using autodialer or prerecorded voice technology. You also
                      agree that all calls to you may be recorded. Your consent is not a condition of any purchase.
                    </small>
                  </Alert>
                </FormPage>
              </Stack>
            )}
          </AbsoluteCenter>
        </ModalBody>
      </ModalContent>
    </>
  );
}

export default FormModal;
