import { Box, WrapItem } from "@chakra-ui/react";
import React from "react";

function RadioBox(props) {
  return (
    <WrapItem role="group" w={props.boxWidth} px="2">
      <Box as="label" w="100%">
        <Box
          textAlign={props.boxAlign}
          cursor="pointer"
          borderWidth="1px"
          borderRadius="md"
          boxShadow="md"
          p="1rem"
          _groupHover={{
            bg: "gray.100",
          }}
          _groupFocus={{
            boxShadow: "outline",
          }}
        >
          {props.children}
        </Box>
      </Box>
    </WrapItem>
  );
}
export default RadioBox;
